<template>
  <base-view
    title="Pólizas Recursos Humanos"
    description="Visualiza la información de las pólizas importadas por Recursos Humanos"
    background="bg-gradient-purple-to-black"
    icon="repeat"
    header-type="overlap"
  >
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <div class="col">
            <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
              <router-link :to="{ name: 'PeriodosFiscales' }">
                <b-button size="sm">
                  Cambiar periodo fiscal activo
                </b-button>
              </router-link>
            </check-authorization>
          </div>
          <div class="col">
            <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
          </div>
        </div>
      </b-card-header>

      <div class="row py-1 p-4">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Filtrar por</b-input-group-text>
          </template>

          <b-form-select
            :options="[
                { text: 'Pendientes', value: 0 },
                { text: 'Autorizados', value: 1 },
                { text: 'Rechazados', value: 2 },
              ]"
            @input="onChangeEstado"
          >
          </b-form-select>
        </b-input-group>
      </div>

      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <div class="row my-1 p-4">
          <b-table
            :fields="fields"
            :items="polizas"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(concepto)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item.concepto }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
              </div>
            </template>
            <template #cell(folio)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.folio }}</b-badge>
              </div>
            </template>
            <template #cell(mes)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="secondary" class="ml-2">
                  {{ getMonthById(data.item.mes).value }}
                </b-badge>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ $formatNumber(data.item.total) }}</b-badge>
              </div>
            </template>
            <template #row-details>
              <movimientos-list :traspaso="selectedTraspaso"></movimientos-list>
            </template>
            <template #cell(acciones)="row">
              <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="info"
                  @click="
                    row.toggleDetails()
                    onShowMovimientos(row.item.id)
                  "
                >
                  Ver Movimientos
                </b-button>

                <check-authorization :requiresAuthorizations="['exportar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="primary"
                    @click="onExport(row.item.id, row.item.folio)"
                  >
                    Exportar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['autorizar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="success"
                    @click="onAuthorize(row.item.id, 1)"
                  >
                    Autorizar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['rechazar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="danger"
                    @click="onAuthorize(row.item.id, 0)"
                  >
                    Rechazar
                  </b-button>
                </check-authorization>

                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="green"
                  @click="downloadAcuse(row.item.id, row.item.folio)">
                  Acuse
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-card>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import MovimientosList from '@/components/RecursosFederales/MovimientosList'

export default {
  name: 'PolizasRHListView',

  components: {
    MovimientosList
  },

  data () {
    return {
      fields: ['folio', 'fecha', 'mes', 'concepto', 'momento', 'total', 'acciones'],

      loading: false,

      selectedPoliza: null,

      polizas: null
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById'])
  },

  methods: {
    async onChangeEstado (value) {
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      const response = await this.$store.dispatch('polizasModule/listar', {
        periodo_fiscal_id: this.periodoActivo.id,
        modulo: 'RH'
      })

      this.polizas = response.data
      this.loading = false
    },

    onShowMovimientos (id) {
      this.selectedPoliza = this.polizas.find(el => el.id === id)
    },

    async downloadAcuse (id, folio) {
      const getval = await this.$store.dispatch('recursoFederal/downloadAcuse', id)

      await this.$onDownload(getval, `acuse.${folio}.pdf`)

      this.$notify({ message: `Solicitud de descarga de acuse de traspaso con folio ${folio}.` })
    }
  }
}
</script>
